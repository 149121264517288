<template>
  <div class="finance">
   <main-nav />
    <main-titlebar myclass="yellowBar" showback="true" icon="fas fa-circle-notch" />
  
     <div class="gen-list-wrapper">
          <div v-for="group in groups" :key="group.id" class="trip-row-wrapper" @click="viewGroup(group.id)"  >
            <div class="trip-row" >
              <div class="trip-col1 textAlignLeft">{{ group.name}}</div>
              <div class="trip-col1 textAlignRight"><i class="fa-solid fa-user"></i> {{ group.members.length}}</div>
            </div>
          </div>
        </div>

  </div>
</template>

<script>
import {ref} from 'vue'
import CircleService from "../../services/circle-service";

export default {
  name:'groups',
  data() {
    const search = ref('')
  var status = 'loading'
    var groups;
    return { groups,search, status }
  },
  methods: {
    viewGroup(id) {
      this.$router.push('/circles/view/' + id);
    }
  },
  computed: {
    curUserID() {
      // var tripOwner = this.trips[0]["owner"]
      var user  = this.$store.state.auth.user['userid'];
      return user;
    }
  },
  mounted() {
    var targ = this;
    CircleService.getMyCircles().then(
      (response) => {
        console.log("CircleService.getMyCircles: data loaded from: " + JSON.stringify(response));
        targ.status = response.status;
        targ.groups = response.data;
      },
      (error) => {
        targ.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  components: {
    'main-titlebar': require('@/components/titlebar.vue').default,
    'main-nav': require('@/components/mainmenu.vue').default,

  }
}
</script>
