import axios from 'axios';
import authHeader from './auth-header';
const API_URL = process.env.VUE_APP_TRIPTRADER_API_HOST;
class CircleService {

  getMyCircles() {
    return axios.get(API_URL + 'my/circles',{ headers: authHeader() }).then(response => {
      console.log("getMyCircles response: " + JSON.stringify(response.data));
      if(response.data.status == "failed") {
        console.log("Response has  NO token:" );
        // localStorage.setItem('user', null);
        // localStorage.removeItem('user');
      }
      return response.data;
    });
  }
  getCircle(id) {
    return axios.get(API_URL + '/circles/'+id,{ headers: authHeader() }).then(response => {
      console.log("getCircle response: " + JSON.stringify(response.data));
      if(response.data.status == "failed") {
        console.log("Response has  NO token:" );
        // localStorage.setItem('user', null);
        // localStorage.removeItem('user');
      }
      return response.data;
    });
  }
 
  createCircle(group) {
    return axios
    .post(API_URL + 'my/circles/create', group,{ headers: authHeader() } )
    .then(response => {
      console.log("response: " + JSON.stringify(response.data));
      return response.data;
    });
  }
 
}
export default new CircleService();